<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title color="white">Nuova prenotazione</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <form @submit.prevent="creaRichiesta()">
                    <ion-list class="fields">
                        <!-- Data inizio -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Data inizio
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="date_start"
                                        display-format="DD/MM/YYYY"
                                        picker-format="DD/MM/YYYY"
                                        min="2021"
                                        max="2099"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="richiesta.data_inizio"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Ora inizio -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Ora inizio
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="start_time"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="richiesta.ora_inizio"
                                        placeholder="Seleziona ora di inizio"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Automezzo -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Automezzo
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-select
                                        name="tipologia"
                                        interface="action-sheet"
                                        cancel-text="Annulla"
                                        ok-text="Conferma"
                                        v-model="richiesta.automezzo"
                                    >
                                        <ion-select-option v-for="automezzo in automezzi" :key="automezzo.automezzi_id" :value="automezzo.automezzi_id">
                                            {{ `${automezzo.automezzi_marca} ${automezzo.automezzi_modello} ` }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Luogo destinazione -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Luogo destinazione
                                </div>
                                <div class="value">
                                    <ion-textarea
                                        type="text"
                                        rows="2"
                                        v-model="richiesta.luogo_destinazione"
                                        placeholder="Inserisci il luogo di destinazione"
                                        class="custom_input"
                                    >
                                    </ion-textarea>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Note -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">Motivazione</div>
                                <div class="value">
                                    <ion-textarea
                                        type="text"
                                        rows="3"
                                        v-model="richiesta.motivazione"
                                        placeholder="Inserisci la motivazione della prenotazione"
                                    >
                                    </ion-textarea>
                                </div>
                            </div>
                        </ion-item>

                        <div class="action">
                            <button type="submit" class="btn_prenota">
                                Crea prenotazione
                            </button>
                        </div>
                    </ion-list>
                </form>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    modalController,
    IonList,
    IonItem,
    IonDatetime,
    IonTextarea,
    IonText,
    IonSelect,
    IonSelectOption,
    IonIcon,
} from "@ionic/vue";
import { arrowBackOutline, close } from "ionicons/icons";

import { defineComponent, ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import { openToast } from "@/services/toast";
import apiAutomezzi from "@/services/automezzi";

import moment from "moment";

export default defineComponent({
    name: "ModalNuovaPrenotazione",
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        IonList,
        IonItem,
        IonDatetime,
        IonTextarea,
        IonText,
        IonSelect,
        IonSelectOption,
        IonIcon,
    },
    setup(props, context) {
        const router = useRouter();

        const start_date = new Date();
        const start_hour = new Date();
        const end_hour = new Date();
        start_hour.setHours(start_hour.getHours());
        end_hour.setHours(end_hour.getHours() + 2);

        const userID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).users_id; //set userID in user field
        const dipendenteID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_id;

        const richiesta = reactive({
            user: "", //from logged user
            data_inizio: start_date.toISOString(),
            //data_fine: start_date.toISOString(),
            data_fine: "",
            ora_inizio: start_hour.toISOString(),
            ora_fine: "",
            tipologia: "",
            automezzo: "",
            luogo_destinazione: "",
            km_effettuati: "",
            motivazione: "",
        });

        const automezzi = ref([]);

        const successResponse = ref(false);

        //console.log(dipendenteID);

        const fieldFlag = ref(false); //se tutti i campi sono ok diventa true e posso procedere con la creazione

        /**
         * Close modal after form data submit
         */
        async function closeModalOnSubmit(successResponse, res) {
            if (successResponse.value) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        /**
         * Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * Create new prenotazione
         */
        function creaRichiesta() {
            richiesta.user = dipendenteID; //set user as logged user id

            const data_inizio_formatted = moment(richiesta.data_inizio).format("YYYY-MM-DD");
            const ora_inizio_formatted = richiesta.ora_inizio != "" ? moment(richiesta.ora_inizio).format("HH:mm") : "";

            const data_ora_inizio = data_inizio_formatted + " " + ora_inizio_formatted;

            const oraInizio = moment(richiesta.ora_inizio).valueOf();
            const oraFine = richiesta.ora_fine ? moment(richiesta.ora_fine).valueOf() : null;

            //Se non ho automezzo mostro errore
            if (!richiesta.automezzo) {
                openToast("L'automezzo da prenotare è obbligatorio", "toast_danger");
                return;
            }
            const momentInizio = moment(richiesta.data_fine).format("YYYY-MM-DD");
            const momentOggi = moment().format("YYYY-MM-DD");
            //Se inserisco una data_inizio già passata non devo permettere l'inserimento
            if (moment(richiesta.data_inizio).isBefore(momentOggi, "day")) {
                openToast("Non puoi inserire una richiesta per una data precedente ad oggi", "toast_danger");
                return;
            }

            const data = new FormData();
            data.append("prenotazioni_automezzi_dipendente", dipendenteID); //logged user
            data.append("prenotazioni_automezzi_data_ora_inizio", data_ora_inizio);
            data.append("prenotazioni_automezzi_automezzo", richiesta.automezzo);
            data.append("prenotazioni_automezzi_luogo_destinazione", richiesta.luogo_destinazione);
            data.append("prenotazioni_automezzi_km_effettuati", richiesta.km_effettuati);
            data.append("prenotazioni_automezzi_motivazione", richiesta.motivazione);

            apiAutomezzi
                .savePrenotazione(data)
                .then((response) => {
                    if (response.data.status == 8) {
                        openToast(response.data.message, "toast_danger");
                    } else {
                        const res = response.data.data[0];
                        successResponse.value = true;
                        closeModalOnSubmit(successResponse, res);
                    }
                })
                .catch((error) => {
                    //console.error(error);
                    openToast("Errore durante la creazione della prenotazione", "toast_danger");
                })
                .finally(() => {
                    //console.log("fine creazione prenotazione");
                });
            //}
        }

        onMounted(() => {
            apiAutomezzi
                .getAutomezzi()
                .then((response) => {
                    //console.log(response);
                    automezzi.value = response;
                })
                .catch((error) => {
                    error.value = true;
                    openToast("Errore durante la richiesta degli automezzi", "toast_danger");
                })
                .finally(() => {
                    //console.log("fine chiamata richiesta tipologie ");
                });
        });

        return {
            richiesta,
            creaRichiesta,
            closeModal,
            userID,
            automezzi,
            arrowBackOutline,
            close,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.page_content {
    width: 100%;
    position: relative;
    min-height: 100%;
    background: #f2f2f2;
    padding: 16px;
}

.fields {
    width: 100%;
    border-radius: 4px;
    /*padding: 10px;*/
    padding: 16px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 8px;
}

.field_title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #6b7280;
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --padding-left: 0;
    --padding-right: 0;
    --inner-padding-end: 0;
    --ripple-color: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 0;
    margin-bottom: 4px;
}

ion-datetime,
ion-select {
    max-width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    --padding-start: 8px;
}

ion-input,
ion-textarea {
    --placeholder-color: #6b7280;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
    margin-top: 0;
    border: 1px solid #d1d5db;
    border-radius: 4px;
}
.custom_input textarea::placeholder {
    font-size: 5px;
}

/* .btn_prenota {
  --background: #16a34a;
  --background-activated: #15803d;
  --background-hover: #15803d;
  --background-focused: #15803d;
  --box-shadow: none;
  --border-radius: 4px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-start: 10px;
  --padding-end: 10px;
  --ripple-color: none;
  --color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
} */
.btn_prenota {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: rgb(22 163 74);
    color: #ffffff;
}
ion-button {
    --color: #ffffff;
}
</style>
